<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.23077 1.31363H1.85209C1.2998 1.31363 0.852085 1.76135 0.852085 2.31363V9.68641C0.852085 10.2387 1.2998 10.6864 1.85209 10.6864H9.22487C9.77715 10.6864 10.2249 10.2387 10.2249 9.68641V8.3077"
      stroke="currentColor"
      stroke-linecap="round"
    />
    <path
      d="M5.96439 5.57398L10.2247 1.31363"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.96439 1.31363H10.2247V5.57398"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>
